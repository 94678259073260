<template>
    <div>
        <section class="developer_header">
            <div class="developer_header_first_box">
                <div class="developer_header_title_box">
                    Мы делаем клиентов счастливыми
                </div>
                <div class="developer_header_button_box">
                    <input type="text" placeholder="+7"><button type="button" class="developer_button_call_me">Позвоните мне</button>
                </div>
                <div class="developer_small_box">
                <small class="developer_header_small">Лучшие решения для ваших задач</small>
                    
                </div>
                
            </div>
            <div class="developer_header_last_box"></div>
        </section>
    </div>
</template>
<script>
export default {
    name: "Developer",
    components: {},
    data() {
        return {

        }
    },

    methods: {},

}
</script>
<style scoped>
html {
    overflow-y: scroll;
}

* {
    transition: all .3s ease;
}

div {
    display: block;
}

button,
input {
    outline: none;
    border: none;
}
.developer_header {
    display: flex;
    min-height: 100vh;
    width: 100%;
}

.developer_header_first_box {
    background: #eeeef1;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 50%;
}

.developer_header_title_box {
    font-size: 60px;
    font-family: MuseoSansCyrl-900,sans-serif;
    line-height: 65px;
    margin-bottom: 40px;
    max-width: 513px;
    text-align: left;
    font-weight: bold;
 }


.developer_header_small {
    font-family: MuseoSansCyrl-900,sans-serif;
    font-weight: bold;    
    color: rgba(32,31,30,.5);
}

.developer_header_button_box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.developer_header_button_box input {
    display: flex;
    border: none;
    padding: 20px 40px;
    max-width: 298px;
    font-size: 18px;
    color: rgba(32,31,30,.5);
}

.developer_button_call_me {
    background: #ffd51a;
    font-size: 18px;
    padding: 20px 40px;
}


.developer_header_last_box {
    background: #eeeef1;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 50%;
    background: #000;
}
</style>